<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7735 4.50532L13.4401 5.83865V2.66732H7.44012V6.00065H4.10679V13.334H13.4401V11.496L14.7735 10.1627V14.006C14.7733 14.1814 14.7035 14.3497 14.5793 14.4737C14.4552 14.5977 14.2869 14.6673 14.1115 14.6673H3.43545C3.34791 14.6667 3.26133 14.6489 3.18068 14.6148C3.10003 14.5807 3.02688 14.5311 2.96541 14.4688C2.90394 14.4064 2.85535 14.3326 2.82241 14.2515C2.78947 14.1703 2.77284 14.0835 2.77345 13.996V5.33398L6.77545 1.33398H14.1055C14.4735 1.33398 14.7735 1.63732 14.7735 1.99532V4.50532ZM15.2921 5.87198L16.2348 6.81532L11.0495 12.0007L10.1055 11.9993L10.1068 11.058L15.2921 5.87265V5.87198Z"
      fill="currentColor"
    />
  </svg>
</template>
